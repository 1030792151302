.usm-title {
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 0.55rem;
}

.usp-button-holder {
  margin-top: auto;
}

.usm-top-text {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: 22px;
}

.usm-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ar-button {
  color: white;
  background-color: rgb(23,164,150);
  border-radius: 5px;
  border-width: 0;
  line-height: 2.4rem;
  width: 230px;
  font-weight: 600;
  margin-top: 13px;
  margin-bottom: 22px;
  margin-left: auto;
  margin-right: auto;
}

a.ar-button-a {
  color: white;
  margin: 0 auto;
}

.ar-button:hover {
  opacity: 0.85;
}

.usm-col {
  display: flex;
}

.usm-col-container {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(66,154,142);
  padding-top: 15px;
  padding-left: 15px;
  border-radius: 7px;
}

.usm-benefits ul {
  padding-left: 30px;
  font-size: 15px;
}

li.usm-li-level2 {
  list-style: none;
}

li.usm-li-level2 ul {
  padding-left: 20px;
}

li {
  padding-top: 1px;
}

.ar-disclaimer-non-broker {
  font-size: 1.04rem;
  color: #5f5e5e;
  padding-top: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
  font-weight: 500;
  text-align: center;
}
