* {
  margin-left: 0;
  margin-right: 0;
}

.content {
  padding: 2% 0 0 0;
}
.dropdown-menu.show{
  margin-left: 5% !important,
}
