.features-subtitle-row {
  background-color: $theme-green;
}

.features-subtitle-text {
  margin-top: auto;
  margin-bottom: auto;
  padding: 0.3rem 0rem 0.3rem 1.3rem;
  color: $white;
  font-weight: bold;
  font-size: 18px;
}

.features-content {
  padding: 3rem 0rem 3rem 0rem;
  text-align: left;
}

.features-content-icons {
  padding: 3rem 0rem 3rem 0rem;
  text-align: center;
}

.features-content-detail {
  padding: 0.8rem 0rem 0.8rem 0rem;
}

.features-content-detail-text {
  padding: 0rem 1.3rem 0rem 0rem;
  margin-left: 0.9rem;
  font-weight: normal;
  color: $theme-grey;
  font-size: 14px;

  a {
    color: $theme-green;
  }
}

.features-details {
  padding: 0rem 0rem 0rem 3rem;
}

.second-img-of-features {
  padding: 0rem 3rem 0rem 3rem;
}

.features-diversified-chart {
  padding: 1rem 2rem 1rem 2rem;
}

.feature-inner-href {
  color: $theme-green;
}

.diversified-title {
  font-weight: bold;
  font-size: 28px;
  color: $font-title-grey;
  padding: 0rem 0rem 0rem 0rem;
}

.diversified-text-show {
  font-weight: normal;
  font-size: 14px;
  text-align: justify;
  color: $theme-grey;
}

.diversified-text-hide {
  font-weight: normal;
  font-size: 14px;
  color: $theme-grey;
  text-align: justify;
  padding: 0.9rem 0rem 0rem 0rem;
}

.diversified-charts-switch {
  padding: 0rem 1.8rem 0rem 1.8rem;
}

.diversified-features-content {
  padding: 1.7rem 1rem 3rem 1rem;
}

.diversified-text-button {
  color: $theme-green;
  border: none;
  outline: none;
  background-color: $white;
}

.diversified-text-button:active {
  border: none;
}

.features-content-detail-title {
  padding: 0rem 1.3rem 0rem 0rem;
  margin-left: 0.9rem;
  font-weight: bold;
  color: $font-title-grey;
  font-size: 16px;
}

.features-diversified {
  padding: 2rem 0rem 0rem 0rem;
}



.concept-phoneview-col {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.concept-investment {
  margin-bottom: 4rem;
}

.concept-investment-text {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 0.6rem;
}

.concept-investment-text-r {
  font-size: 26px;
  vertical-align: bottom;
}

.concept-investment-text-100 {
  color: $theme-green;
}

.concept-investment-text-subtitle {
  font-size: 20px;
  margin-bottom: 0;
  color: $theme-grey;
}

.concept-investment-text-button {
  background-color: $theme-green;
  color: $white;
  padding: 0.3rem 0.9rem 0.3rem 0.9rem;
  font-size: 18px;
  border: none;
  border-radius: 18px;
}

.concept-line-blank-row {
  padding: 1.2rem 0rem 0rem 0rem;
}

.concept-line-blank-row-2 {
  padding: 3rem 0rem 0rem 0rem;
}

.features-store-download {
  width: 100%;
}

.mobile-buttons-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media only screen and (max-width: 768px) {
  .concept-investment-sub {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 1000px) {
  .mobile-button {
    width: 40%;
  }
}

@media only screen and (min-width: 1000px) {
  .mobile-button {
    width: 32%;
  }
}

@media only screen and (max-width: 600px) {

  .diversified-charts-switch {
    padding: 0rem 0.2rem 0rem 0.2rem;
  }

  .features-content-icons {
    padding: 0.5rem 1rem 1rem 0rem;
  }

  .features-content {
    padding: 1rem 1rem 0rem 1rem;
  }
}
