.top-return-text {
  font-size: 2.3rem;
  color: $theme-green;
  padding: 3rem 0rem 0rem 0rem;
}

.top-perform-table {
  text-align: center;
  width: 100%;
}

.top-table-col {
  text-align: center;
  padding: 1.3rem 0rem 0rem 0rem;
}

.allocaterite-tr {
  color: $theme-green;
  font-size: 1.2rem;
}

.others-tr {
  color: $theme-grey;
  font-weight: normal;
}

.last-tr {
  border-bottom: 1px solid #ddd;
}

.table-privacy-issue {
  font-size: 0.8rem;
  color: $font-text-grey;
  padding: 2rem 0rem 4rem 0rem;
  text-align: justify;
}

.italic {
  font-style: italic;
}

.top {
  font-style: italic;
}

@media only screen and (max-width: 600px) {
  .table-privacy-issue {
    padding: 2rem 1rem 4rem 1rem;
  }

  .top-return-text {
    font-size: 2rem;
  }
}
