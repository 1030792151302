.monthly-commentary:hover {
  text-decoration: underline;
  color: $theme-green;
}

.monthly-commentary:active {
  text-decoration: none;
  color: $theme-green;
  font-weight: bold;
}

.monthly-commentary {
  text-decoration: none;
  color: $theme-green;
  font-weight: bold;
  white-space: nowrap;
  padding: 0rem 0.25rem 0rem 0.25rem;
}

.monthly-commentary-lines {
  text-align: center;
  padding: 0rem 0rem 1.7rem 0rem;
}

.year-list-commentary {
  padding: 1.6rem 1.8rem 1rem 1.8rem;
}

.insight-page-title {
  font-size: 1.6rem;
  font-weight: normal;
  padding: 0rem 0rem 0rem 0rem;
}

.insights-calendar-commentary {
  padding: 0rem 2rem 1rem 2rem;
}
