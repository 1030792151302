.NavLink:hover {
  text-decoration: none;
  color: $white-hover;
}

.NavLink:active {
  text-decoration: none;
  color: $white-hover;
  font-weight: bold;
}

.navlink-chinese-button {
  border: none;
  background-color: $background-grey;
}

.navlink-chinese-button:focus {
  border: none;
  background-color: $background-grey;
  outline: 0;
}

.NavLink {
  text-decoration: none;
  color: white;
  font-weight: bold;
  white-space: nowrap;
  padding: 0.8rem 1rem 0.8rem 1rem;
}

header {
  color: $black;
}



.span-in-header {
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding: 0.8rem 1rem 0.8rem 1rem;
}

.span-in-header:hover {
  text-decoration: none;
  color: $white-hover;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1.0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.span-in-header:active {
  text-decoration: none;
  color: $white-hover;
  font-weight: bold;
}

#nav-toggle-button {
  border: none;
  padding: 0rem 0rem 0rem 0rem;
  margin-left: auto;
}

.navbar-brand {
  margin-left: 0;
  margin-right: 0;
}

.header-rightpart {
  text-align: right;
}

.header-logo {
  width: 150%;
}

.header-logo:hover {
  opacity: 0.7;
}

.navbar-bg-allocate {
  background-color: #149e91!important;
}

@media only screen and (max-width: 600px) {
  .header-logo {
    width: 100%;
  }
}
