#footer{
    background-color: $background-grey;
}

.foot-blank-row{
    padding: 1.3rem 0rem 0rem 0rem;
}

.terms{
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.p1{
    color: $theme-grey;
    font-size: 12px;
    text-align:justify;
}

.p2{
    color: $theme-grey;
    font-size: 12px;
    text-align:justify;
}

#copyleft{
    text-align: left;
}

#copyright{
    text-align: right;
}

#copyLeftText{
    font-size: 12px;
    font-weight: bold;
}

#copyRightText{
    font-size: 12px;
    font-weight: bold;
}

.footer-policy-text{
    padding: 0rem 1rem 0rem 1rem;
}

.footer-navlink{
    font-size: 0.8rem;
    color: $black;
    font-weight: bold;
    padding: 0rem 1rem 0rem 1rem;
}

.footer-navlink:hover{
    color: $theme-green;
}

@media only screen and (max-width: 600px){
    .footer-navlink{
        padding: 0rem 0.6rem 0rem 0.6rem;
    }

    #copyright{
        text-align: left;
        margin-top: auto;
        margin-bottom: auto;
    }

}