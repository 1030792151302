.platform-branch-element{
    padding: 3rem 0rem 3rem 0rem;
}

.branch-element-details{
    background-color: $background-grey;
    padding: 2rem 3rem 2rem 3rem;
    text-align: justify;
}

.platform-element-details-title{
    color: $theme-green;
    font-weight: bold;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0.8rem 0rem 0.2rem 0rem;
}

.platform-element-details-text{
    color: $font-text-grey;
}

.platform-element-text-col{
    padding: 1rem 1rem 1rem 1rem;
}

.platform-branch-element-title{
    font-size: 2.8rem;
    padding: 1rem 0rem 0rem 0rem;
    color: $theme-green;
}

.platform-branch-element-subtitle{
    font-size: 1.3rem;
    color: $font-title-grey;
}

.platform-branch-element-text{
    color: $font-text-grey;
}

.roulette-chart-container{
    text-align: center;
    position: relative;
}

.roulette-chart-title{
    font-size: 1.9rem;
    padding: 4rem 0rem 3rem 0rem;
    color: $font-title-grey;
}

.roulette-chart-tooltip-title{
    color: $white;
    font-weight: bold;
}

.roulette-chart-tooltip-text{
    font-size: 0.7rem;
}

.roulette-chart-tooltip > .tooltip-inner {
    background-color: $theme-grey;
}

.roulette-chart-tooltip-button{
    background-color: $theme-green;
    font-weight: bold;
    border-radius: 0.7rem;
    padding: 0.1rem 0.7rem 0.1rem 0.7rem;
    color: $white;
    border: none;
}

#roulette-chart{
    width: 60%;
    
}

#tooltip-button-1{
    position: absolute;
    top: 18.3%;
    left: 56.3%;
}

#tooltip-button-2{
    position: absolute;
    top: 43.9%;
    left: 67%;
}

#tooltip-button-3{
    position: absolute;
    top: 73%;
    left: 60.5%;
}

#tooltip-button-4{
    position: absolute;
    top: 78%;
    left: 41%;
}

#tooltip-button-5{
    position: absolute;
    top: 52.5%;
    left: 30%;
}

#tooltip-button-6{
    position: absolute;
    top: 23.8%;
    left: 36.5%;
}

@media only screen and (max-width: 600px) {
    #roulette-chart{
        width: 95%;
        padding: 1rem 0rem 0rem 0rem;
    }

    #tooltip-button-1{
        position: absolute;
        width: 4px;
        padding: 3px 12px 3px 10px;
        height: 24px;
        font-size: 12px;
        top: 24%;
        left: 58.3%;
    }
    
    #tooltip-button-2{
        position: absolute;
        width: 4px;
        padding: 3px 12px 3px 9px;
        height: 24px;
        font-size: 12px;
        top: 44.5%;
        left: 75.5%;
    }
    
    #tooltip-button-3{
        position: absolute;
        width: 4px;
        padding: 3px 12px 3px 10px;
        height: 24px;
        font-size: 12px;
        top: 67.2%;
        left: 64%;
    }
    
    #tooltip-button-4{
        position: absolute;
        width: 4px;
        padding: 3px 12px 3px 10px;
        height: 24px;
        font-size: 12px;
        top: 70%;
        left: 35.4%;
    }
    
    #tooltip-button-5{
        position: absolute;
        width: 4px;
        padding: 3px 12px 3px 10px;
        height: 24px;
        font-size: 12px;
        top: 50%;
        left: 19%;
    }
    
    #tooltip-button-6{
        position: absolute;
        width: 4px;
        padding: 3px 12px 3px 10px;
        height: 24px;
        font-size: 12px;
        top: 27%;
        left: 30%;
    }
}

.platform-title{
    text-align: center;
}

.platform-advertise{
    padding: 2rem 1.3rem 5rem 1.3rem;
}

.platform-advertise-title{
    font-size: 2.3rem;
    font-weight: bold;
}

.platform-advertise-text{
    font-size: 1.5rem;
    font-weight: normal;
    color: $theme-green;
}

.platform-advantage{
    background-color: $background-grey;
}

.platform-advantage-title{
    font-size: 2.3rem;
    font-weight: bold;
    color: $theme-green;
    padding: 1.5rem 2.3rem 0.5rem 1.8rem;
}

.platform-advantage-details{
    padding: 1rem 2.3rem 1rem 1.1rem;
}

.platform-advantage-detail-title{
    font-size: 1.15rem;
    font-weight: bold;
    color: $font-title-grey;
    margin-top: auto;
    margin-bottom: auto;
}

.platform-advantage-details-text{
    font-weight: normal;
    color: $font-text-grey;
    padding: 0.1rem 0rem 0.6rem 0rem;
}

.secure-you-future-title-col{
    text-align: center;
}

.secure-you-future-button-col{
    text-align: center;
}

.secure-you-future-title{
    font-size: 2.3rem;
    padding: 6rem 0rem 0.8rem 0rem;
    color: $font-title-grey;
}

@media only screen and (max-width: 600px){
    .platform-title{
        padding: 3rem 0rem 3rem 0rem;
    }

    #roulette-chart{
        padding: 3rem 0rem 3rem 0rem;
    }

    .platform-advertise-title{
        font-size: 1.8rem;
    }
    
    .platform-advertise-text{
        font-size: 1rem;
    }

    .platform-advantage-title{
        font-size: 1.6rem;
    }
}

