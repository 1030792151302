.terms-top-paragraph {
  padding: 3rem 0rem 0rem 0rem;
}

.terms-text {
  padding: 0.5rem 0rem 0.5rem 0rem;
  color: $font-text-grey;
  text-align: justify;
}

.terms-bottom-paragraph {
  padding: 0.18rem 0rem 2rem 0rem;
  color: $font-text-grey;
}

.terms-title {
  padding: 0rem 0rem 1.4rem 0rem;
}

.terms-paragraph-row {
  padding: 1.5rem 0rem 0rem 0rem;
}

.terms-subparagraph-row {
  padding: 0.18rem 0rem 0rem 0rem;
}

.terms-bottom-date {
  color: $black;
  font-size: 0.86rem;
  font-weight: bold;
}

.terms-bottom-row {
  text-align: right;
}

.terms-contact-us {
  color: $theme-green;
}

.terms-contact-us:hover {
  color: $theme-green;
  text-decoration: underline;
}

.terms-link {
  color: $theme-green;
}

.terms-link:hover {
  color: $theme-green;
  text-decoration: underline;
}

.terms-col {
  padding: 0rem 0rem 0rem 0rem;
  margin-left: 0;
}
